import { Action } from '@ngrx/store';
import { type } from '../../../util';
import { IFormInputChangePayload } from '../../abstract.components/abstract.form.component';
import { ITaxFormData, ITaxSelectModels } from './tax-document.reducer';

export const ActionTypes = {
  FORM_CONTROL_CHANGE: type('[Tax document form] form control change'),
  FETCH_TAX_TYPES: type('[Tax document form] fetch tax select models'),
  STORE_TAX_TYPES: type('[Tax document form] store tax select models'),
  GET_RECRUIT_FORM_DATA: type('[Tax document form] get recruit info'),
  STORE_RECRUIT_FORM_DATA: type('[Tax document form] store recruit info'),
  SUBMIT_TAX_DOCUMENT: type('[Tax document form] submit form'),
  FETCH_TAX_INFO: type('[Tax document view] fetch tax info'),
  STORE_TAX_INFO: type('[Tax document view] store tax info'),
};

export class TaxFormControlChange implements Action {
  public type = ActionTypes.FORM_CONTROL_CHANGE;

  constructor(public payload: IFormInputChangePayload) {}
}

export class FetchTaxBusinessTypes implements Action {
  public type = ActionTypes.FETCH_TAX_TYPES;

  constructor(public payload?: any) {}
}

export class StoreTaxDocumentOptions implements Action {
  public type = ActionTypes.STORE_TAX_TYPES;

  constructor(public payload: ITaxSelectModels | any) {}
}

export class GetTaxRecruitFormData implements Action {
  public type = ActionTypes.GET_RECRUIT_FORM_DATA;

  constructor(public payload?: any) {}
}

export class StoreTaxRecruitFormData implements Action {
  public type = ActionTypes.STORE_RECRUIT_FORM_DATA;

  constructor(public payload?: any) {}
}

export class SubmitTaxDocument implements Action {
  public type = ActionTypes.SUBMIT_TAX_DOCUMENT;

  constructor(public payload: ITaxFormData) {}
}

export class FetchTaxDocumentInfo implements Action {
  public type = ActionTypes.FETCH_TAX_INFO;

  constructor(public payload?: any) {}
}

export class StoreTaxDocumentInfo implements Action {
  public type = ActionTypes.STORE_TAX_INFO;

  constructor(public payload: any) {}
}

export type TypeAction =
  TaxFormControlChange |
  StoreTaxDocumentOptions |
  StoreTaxRecruitFormData |
  StoreTaxDocumentInfo |
  FetchTaxBusinessTypes |
  FetchTaxDocumentInfo |
  SubmitTaxDocument |
  GetTaxRecruitFormData;
